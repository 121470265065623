/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 599px),
	'medium': 		(max-width: 767px),
	'large': 		(max-width: 1024px),
	'xxl': 			(min-width: 1200px)
);


/* =======================================================================
Font-face
========================================================================== */

$montserrat: 'Montserrat', sans-serif; // extrabold 800
$roboto: 'Roboto', sans-serif; // regular 400, medium 500, bold 700


/* =======================================================================
Variables
========================================================================== */

// Colors
$blue: 					  #083d77;
$blue-light: 			  #1b84f6;
$blue-dark:				  #062a51;
$black:                   #393838;
$white:					  #f2f4f6;
$grey-light:			  #fafafa;
$grey-border:			  #cecece;

// Fonts stacks
$font-stack:              $roboto, Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              $black;
$color-link:              $blue;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);

// Highlight
$color-selection:         $blue;
$color-placeholder:       $white;
