#c-copyright {
	padding:40px;
	background:$grey-light;
    border-bottom: 4px solid $blue-light;

    @include mq('xsmall') {
    	padding:40px 20px;
    }
}

#c-copyright p {
	margin-bottom:0px;

	@include mq('xsmall') {
		text-align:center;
		max-width:200px;
		margin-bottom:25px;
	}
}


#c-copyright img {
	@include mq('medium') {
		margin:20px 0px;
	}
}


.cc-reference {
	display:flex;
	flex-flow:row wrap;
	justify-content:flex-end;
	align-items:center;

	a {
		margin-left:10px;
	}

	img {
		width:30px;
		height:auto;
	}
}