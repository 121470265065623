/* =======================================================================
Navigation
========================================================================== */
.nav-wrapper {
    flex-grow: 1;
    background:#083d77;
	padding:20px !important;

	@include mq('medium') {
		padding:20px 10px 20px 0px!important;
	}

	& > .flex,
	& > .wow {
		z-index: 9999;
	}
}

.menu-menu-principal-container {
	width:100%;
}

#menu-menu-principal {
	list-style-type:none;
	padding-left:0px;

	@include mq('large') {
		display:none;
		margin:0px !important;
	}

	& > li > a {
		transition:all 300ms ease-in-out;
	}

	& > li:hover > a {
		color:$blue-light;
	}
	
}

#menu-item-19 > a {
	pointer-events:none;
}

#menu-menu-principal li a {
	font-family:$montserrat;
	font-size:14px;
	line-height:20px;
	text-transform:uppercase;
	color:$white;
	-webkit-font-smoothing: antialiased;
	margin-left:55px;
	position:relative;

	@media screen and (max-width:1275px) {
		margin-left:40px;
	}

	@media screen and (max-width:1075px) {
		margin-left:20px;
	}

	@include mq('large') {
		display:none;
	}
}
#menu-menu-principal li:hover > a:before {
	width:100%;
}

#menu-menu-principal li a:focus {
	text-decoration:none !important;
}

#menu-menu-principal li a:hover+.sub-menu {
	display: block;
}
.sub-menu {
	display:none;
}

.nav-wrapper > ul {
	width:100%;
	padding-left:0px;
	margin:0px;
}

.menu-item-has-children {
	position:relative;

	&:before {
		content:'';
		position:absolute;
		top:0;
		right:0;
		bottom:-43px;
		left:0;
		background:transparent;
	}

	@include mq('large') {
		&:before {
			display:none;
		}
	}

	.sub-menu {
		list-style-type:none;
		padding:25px 30px;
		width:auto;
		min-width:350px;
		background:white;
		display: flex;
		flex-flow:column wrap;
		justify-content: flex-start;
		align-items: flex-start;
		position:absolute;
		left:40px;
	    top: calc(100% + 40px);
		z-index: 10;
		border-radius:2px;
		box-shadow:0px 0px 20px rgba(0,0,0,0.1);
		transition:all 300ms ease-in-out;
		opacity: 0;

		&:before {
			content:'';
			display: block;
			width:15px;
			height:15px;
			background:white;
			border-radius:2px;
			transform:rotate(45deg);
			position:absolute;
			top:-3px;
			left:25px;
			pointer-events:none;
		}

		li a {
			color:$blue !important;
			display: block;
			margin-left:0px !important;
			margin-bottom: 15px !important;
			transition:all 300ms ease-in-out;

			&:hover {
				color:$blue-light !important;
			}
		}

		li:last-child a {
			margin-bottom: 0px !important;
		}
	}

	&:hover .sub-menu {
		opacity: 1;
	    top: calc(100% + 20px);

	}
}