.c-mobile-nav {
	height: 100%;
	width: 100%;
	max-width: 400px;
	padding: 40px 20px;
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;
	transform: translate(-100%, 0px);
	background: white;;
	z-index: 9999;
	transition: all 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
	display:flex;
	flex-flow:column nowrap;
	justify-content:center;
	align-items:flex-start;

	ul {
		list-style-type:none;
		padding:0px;
		margin:0px !important;

		li a {
			font-family: $montserrat;
			font-size:14px;
			line-height: 20px;
			text-transform: uppercase;
			color:$blue;
			position:relative;
			padding-bottom:10px;
			display: block;


			&:after {
				content:'';
				display: block;
				margin-top:4px;
				width:0px;
				height:2px;
				background:$blue-light;
				transition:all 300ms ease-in-out;
			}

			&:hover:after {
				width:100%;
			}
		}
	}
}

.c-mobile-nav .sub-menu {
	transition:all 300ms ease-in-out;
	opacity: 0;
}

.c-mobile-nav.active .sub-menu {
	opacity:1;
	position:initial;
	padding:0px;
	box-shadow:none;

	&:before {
		display:none;
	}

	a {
		padding-bottom: 10px;
		margin:0px !important;
	}
}

.c-mobile-nav .menu-item-has-children > a {
		display: none !important;
	}

.c-mobile-nav.active {
	transform: translate(0%, 0px);
}

.js-menu-button {
	display:none;
	font-size:0px;

	&:before {
   		content: "\f0c9";
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size:16px;
		color:white;
		margin-right:10px;
		transition:all 300ms ease-in-out;
	}


	&:after {
   		content: "Menu";
   		font-family: $montserrat;
   		font-size:14px;
   		text-transform: uppercase;
   		color:white;
		-webkit-font-smoothing: antialiased;
	}

	@include mq('large') {
		display: block;
	}
}

.c-mobile-nav .js-menu-button {
	background:$blue-light;
	border:0px;
	outline:0px;
	margin-bottom:40px;

	&:before {
		content:'\f00d';
	}

	&:after {
		content:'Fermer';
	}
}