.c-banner-interne {
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;
	position:relative;
	height: 450px;
	overflow:hidden;
	display:flex;
	flex-flow:column wrap;
	justify-content:center;
	align-items:flex-start;
	padding:40px;

	@include mq('medium') {
		text-align:center;
		align-items:center;
		padding:40px 20px;
	}
}

.c-banner-interne .row {
	width:100%;
	z-index: 1;
}

.c-banner-interne:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: .3;
}

.c-banner-interne--with-overlay:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto 0;
    width: 100%;
    height: 100%;
    background: #083d77;
    opacity: .7;
}

.c-banner-interne h1 {
	margin-top:0px;
	font-family:$montserrat;
	font-size:45px;
	line-height: 50px;
	color:#fff;

	@include mq('medium') {
		font-size:35px;
		line-height: 40px
	}
}

.c-banner-interne .col-md-12 {
	z-index: 1;
}