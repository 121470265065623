.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  
}

.visuallyhidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.visuallyhidden.focusable:active,.visuallyhidden.focusable:focus{clip:auto;height:auto;margin:0;overflow:visible;position:static;width:auto}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}

@mixin text-shadow($offsetX, $offsetY, $blur:0px, $color:#000) {
    text-shadow: $offsetX $offsetY $blur $color;
    filter: dropshadow(color=$color, offx=$offsetX, offy=$offsetY); 
}

// 
// Default 2 steps color
// Vertical gradient, 2 stops
@mixin linear-gradient($colorTop, $stop1, $colorBottom, $stop2:100%)  {
  background: -moz-linear-gradient(top, $colorTop $stop1, $colorBottom $stop2); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$colorTop), color-stop($stop2,$colorBottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $colorTop $stop1,$colorBottom $stop2); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $colorTop $stop1,$colorBottom $stop2); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $colorTop $stop1,$colorBottom $stop2); /* IE10+ */
  background: linear-gradient(to bottom, $colorTop $stop1,$colorBottom $stop2); /* W3C */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=${colorTop}, endColorstr=${colorBottom},GradientType=0 ); /* IE6-8 */
}

  // Top to bottom linear gradient *** EXCLUDE IE 9 SVG FORMAT + Pie Background for IE6 to IE8
  // Vertical gradient, 3 stops
@mixin linear-gradient-tri($colorTop, $stop1, $colorMiddle, $stop2, $colorBottom, $stop3:100%) {
    background: -moz-linear-gradient(top, $colorTop $stop1, $colorMiddle $stop2, $colorBottom $stop3); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop($stop1,$colorTop), color-stop($stop2,$colorMiddle), color-stop($stop3,$colorBottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $colorTop $stop1,$colorMiddle $stop2,$colorBottom $stop3); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $colorTop $stop1,$colorMiddle $stop2,$colorBottom $stop3); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $colorTop $stop1,$colorMiddle $stop2,$colorBottom $stop3); /* IE10+ */
    background: linear-gradient(to bottom, $colorTop $stop1,$colorMiddle $stop2,$colorBottom $stop3); /* W3C */
  }

// Horizontal gradient, 2 stops
@mixin horizontal-gradient($colorLeft, $stop1, $colorRight, $stop2:100%) {
  background: -moz-linear-gradient(left, $colorLeft $stop1, $colorRight $stop2); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop($stop1,$colorLeft), color-stop($stop2,$colorRight)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $colorLeft $stop1,$colorRight $stop2); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $colorLeft $stop1,$colorRight $stop2); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $colorLeft $stop1,$colorRight $stop2); /* IE10+ */
  background: linear-gradient(to right, $colorLeft $stop1,$colorRight $stop2); /* W3C */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=${colorTop}, endColorstr=${colorBottom},GradientType=1 );
}

// Horizontal gradient, 3 stops
@mixin horizontal-gradient-tri($colorLeft, $stop1, $colorMiddle, $stop2, $colorRight, $stop3) {
  background: -moz-linear-gradient(left, $colorLeft $stop1, $colorMiddle $stop2, $colorRight $stop3); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop($stop1,$colorLeft), color-stop($stop2,$colorMiddle), color-stop($stop3,$colorRight)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $colorLeft $stop1,$colorRight $stop2); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $colorLeft $stop1, $colorMiddle $stop2, $colorRight $stop3); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $colorLeft $stop1, $colorMiddle $stop2, $colorRight $stop3); /* IE10+ */
  background: linear-gradient(to right, $colorLeft $stop1, $colorMiddle $stop2, $colorRight $stop3); /* W3C */
}