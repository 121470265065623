.c-form-footer {
	padding:60px 80px;
	background-size: cover;

	@include mq('medium') {
		padding:60px 20px;
	}
}

.c-form-footer h3 {
	color:$white;
}

.c-form-footer h3:after {
	content:'';
	display:block;
	width:40px;
	height:3px;
	background:$blue-light;
	margin:20px 0px 30px;
}

.c-form-footer form input,
.c-form-footer form textarea {
	width:100%;
	font-family:$roboto;
	font-size:15px;
	line-height:24px;
	color:$white;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 10px;
}
.c-form-footer form input:focus,
.c-form-footer form textarea:focus {
	outline:0;
}

.c-form-footer form input {
	background:transparent;
	border:2px solid $white;
	height: 55px;
	padding:0px 18px 0px 40px;
	position:relative;
	margin-bottom:15px;
}

.c-form-footer form textarea {
	background:transparent;
	border:2px solid $white;
	padding:18px 18px 18px 40px;
	height:200px;
}

.form-icon {
	margin-bottom:20px;
	position:relative;
}

.form-icon:before {
	content:'';
	display: flex;
	flex-flow: column wrap;
	justify-content:center;
    font: normal normal normal 15px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position:absolute;
    top:-15px;
    bottom:0;
    left:20px;
    margin:auto 0;
    color:$blue-light;
}

.form-icon.name:before {
	content:'\f2be';
}

.form-icon.tel:before {
	content:'\f095';
}

.form-icon.mail:before {
	content:'\f0e0';
}

.form-icon.message:before {
	content:'\f037';
    top: 25px;
    bottom: initial;
}

div.wpcf7-validation-errors {
	border:0px;
	padding:0px;
	margin:0px;
	color:#ff4141;
    -webkit-font-smoothing: antialiased;
}

span.wpcf7-not-valid-tip {
    -webkit-font-smoothing: antialiased;
    position:absolute;
    bottom:-42px;
    left:0px;
    width:200px;
    font-size:12px;
    color:#ff4141;
}

.invalid .form-icon {
	margin-bottom:30px;
}

div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ng {
	text-align:center;
	padding:20px;
	font-size:12px;
	color:#ff4141;
}

.message .wpcf7-not-valid-tip {
	bottom:-10px;
}

div.wpcf7-mail-sent-ok {
    border: 2px solid #398f14;
    color: #fff;
    text-align: center;
}

.form-button {
	max-width:300px;
	margin:0 auto;

	input {
		padding:0px 40px !important;
		transition:all 300ms ease-in-out;

		&:hover {
			background:white;
			color:$blue;
		}
	}
}