html {
	overflow-x:hidden;
	overflow-y:scroll;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body {
	//@include breakpoint-label;
	background: #fff;
	font-size: $font-size-base;
	line-height: $line-height-base;
	overflow-x:hidden;
}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; text-decoration:none !important; }
	&:focus,
	&:active { color: $color-link-active; text-decoration:none !important; }
}

h1, h2, h3, h4, h5, h6, p {
	-webkit-font-smoothing: antialiased;
}

h1 {

}

h2 {
	font-family:$montserrat;
	font-size:40px;
	line-height:45px;
	color:$black;
	margin:0px 0px 10px;

	@include mq('small') {
		font-size:30px;
		line-height: 35px;
	}
	@include mq('xsmall') {
		font-size:25px;
		line-height: 30px;
	}
}

h3 {
	font-family:$montserrat;
	font-size:18px;
	line-height:25px;
	color:$blue;
	margin:0px 0px 5px;
	text-transform:uppercase;
}

h4 {
	font-family:$roboto;
	font-size:18px;
	line-height:24px;
	font-weight:900;
	font-style:italic;
	color:$blue-light;
	margin:0px 0px 10px;
}

p {
	margin-top:0px;
}	


img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: $color-selection;
	text-shadow: none;
}

::selection {
	background: $color-selection;
	text-shadow: none;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: $color-placeholder;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $color-placeholder;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $color-placeholder;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: $color-placeholder;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
}

// Flex
.flex {
	display:flex !important;
}
.inlineflex {
	display:inline-flex;
}
.flex.rw {
	flex-flow:row wrap;

	@include mq('medium') {
		flex-flow:column wrap;
	}
}
.flex.cw {
	flex-flow:column wrap;
}
.flex.rnw {
	flex-flow:row nowrap;

	@include mq('medium') {
		flex-flow:column wrap;
	}
}
.flex.cnw {
	flex-flow:column nowrap;
}
.flex.jsb {
	justify-content:space-between;
}
.flex.jfe {
	justify-content:flex-end;
}
.flex.jc {
	justify-content:center;
}
.flex.ac {
	align-items:center;
}
.flex.afs {
	align-items:flex-start;
}

// Full width or height
.full-width {
	width:100%;
} 
.full-height {
	height:100%;
} 

.img-fw {
	width:100%;
}

// Text align
.text-align-center {
	text-align:center;
}
.text-align-right {
	text-align:right;
}

// Max width
.mw-850 {
	max-width:850px;
	margin:0 auto 60px;
}
.mw-1200 {
	max-width:1200px;
	width:100%;
	margin:0 auto;
}

// Background preset
.bg-preset {
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
}

// Margin top
.mt-60 {
	margin-top:60px;
}

// Wow Flicker cancel
// .wow {
// 	visibility:hidden;
// }

// Bootstrap
.col-md-6 {
	float:none !important;
}

.col-md-12, .col-md-6, .col-md-4 {
	@include mq('medium') {
		max-width:100%;
	}
}
