.c-image-slider {
	position:relative;
    max-height: 485px;
    height: 100%;
    margin-bottom: 80px;

    @include mq('small') {
        margin-bottom: 40px;
    }

    & > .js-slider-images {
        height:100%;
    }

    & img {
        max-height:445px;
        width:auto;
        margin:0 auto;
        height:100%;

        @include mq('xsmall') {
            max-height:250px;
        }
    }
}

.c-image-slider button[aria-label="Previous"] {
    left: -25px;
}

.c-image-slider button[aria-label="Previous"]:before {
    transform:rotate(180deg);
}

.c-image-slider button[aria-label="Previous"]:before,
.c-image-slider button[aria-label="Next"]:after {
	content:'';
    position:absolute;
    display: block;
    width: 17px;
    height:8px;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin:auto;
    background:url('../images/image-slide-arrow.png') no-repeat;
    background-size:contain;
}
.c-image-slider button[aria-label="Next"] {
    right: -25px;
}

.c-image-slider button[aria-label="Previous"]:focus,
.c-image-slider button[aria-label="Next"]:focus {
	outline:0;
}

.c-image-slider button[aria-label="Previous"],
.c-image-slider button[aria-label="Next"] {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 0;
    display: inline-block;
    z-index: 9999;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0px;
    background:$blue-light;
}

.c-image-slider__bubble-container {
	position:relative;
	height: 120px;
    display: flex !important;
    align-items: center;
	
	&.slick-active .c-image-slider__bubble{
		opacity: 1;
		transform: scale(1);
	}
}

.c-image-slider__bubble {
	width:80px;
	height:80px;
	border-radius:50%;
	background-repeat: no-repeat;
	background-size: cover;
	margin:0 auto;
	opacity:0;
	transition: all 300ms ease-in-out;
	transform-origin: 50% 50%;
	transform: scale(0);
	border:3px solid $grey-border;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
}

.c-image-slider__bubble-container.slick-current .c-image-slider__bubble,
.c-image-slider__bubble:hover {
	border:3px solid $blue-light;
}

.c-image-slider__bubble-container:focus {
	outline:0;
}

.js-slider-menu {
	padding:0px 100px;
    position: absolute;
    bottom: -60px;
    left:0;
    right:0;

    @include mq('medium') {
        display:none;
    }
}