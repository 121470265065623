.c-contact-footer {
	background:$white;
	padding:60px;
	position:relative;

	@include mq('medium') {
		padding:60px 40px;
	}
}

.c-contact-footer ul {
	list-style-type:none;
	padding-left:0px;
}

.c-contact-footer > ul > li {
	display:flex;
	flex-flow: row wrap;
	justify-content:space-between;
	align-items:center;
	-webkit-font-smoothing: antialiased;
	margin-bottom: 40px;

	@include mq('xsmall') {
		flex-flow:column wrap;
		align-items: center;
		text-align: center;
		width: 100%;
	}
}

.c-contact-footer-list a {
	color:$black;
}

.c-contact-footer-icons {
	width: 64px;
	height:64px;
	background:$blue;
	border-radius:50%;
	position:relative;
	box-shadow:0px 0px 40px rgba(25,25,25,0.3);
	margin-right:40px;
	margin-top:-10px;

	@include mq('xsmall') {
		margin-right:0px;
	}
}
.c-contact-footer-icons:before {
	content:'';
	width: 50px;
	height:50px;
	background:transparent;
	border:2px solid $blue-light;
	border-radius:50%;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
}

.c-contact-footer-icons i {
	font-size:18px;
	color:$white;
}

.c-contact-footer-info {
	flex:1 1 auto;
	max-width:100%;

	@include mq('xsmall') {
		margin-top:20px;
	}
}

.c-contact-footer-note {
	font-style:italic;
	color:$blue;
	font-weight:bold;
	line-height:18px;
	margin-top:10px;
}

.c-contact-footer > ul {
	margin-bottom:0px;
}

.c-contact-footer .c-button {
	@include mq('xsmall') {
		margin: 10px auto;
	}
}

.c-contact-footer__wrapper .col-md-6 {
	width:50%;

	@include mq('medium') {
		width: 100%;
	}

}