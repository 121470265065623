#c-section-separator.container-fluid {
	padding-left:0px !important;
	padding-right:0px !important;
}

#c-section-separator .col-md-12 {
    margin-top: -35px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow:column wrap;
    justify-content: center;
    align-items:center;

    @include mq('medium') {
    	align-items:center;
    }
}

#c-section-separator img {
    height: 50px;
    width: 100%;
    min-width:3000px;
}

#c-section-separator {
    display: none !important;
}