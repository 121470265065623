.c-inner-content {
	position:relative;
	padding: 30px 15px;
	
	@include mq('large') {
		text-align: center;
	}
}

.c-inner-content .col-md-8 {
	padding-top:30px;
	padding-left:60px;

	@include mq('medium') {
		padding: 30px 30px;
	}
}

.c-inner-content h2 {
	font-size:28px;
	color:$blue-light;
}

.c-inner-content .page-subtitle {
	font-family:$roboto;
	font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    font-weight: 700;
	color:$blue;
	margin-bottom:30px;

	@include mq('medium') {
		font-size:16px;
		line-height: 22px;
	}
}

.c-inner-content .quote {
	max-width: 100%;
    font-size:14px;
    line-height: 24px;
    position:relative;
	-webkit-font-smoothing: antialiased;
}

.c-inner-content .jsb {
	@include mq('large') {
		justify-content: center;
	}
}

.c-inner-content img {
	margin-bottom:30px;
	margin-top:20px;
}

.c-inner-content strong {
	font-style:italic;
	color:$blue;
}

.c-inner-content__list {
	padding-left:12px;
	list-style-type:none;
}

.c-inner-content__list li  {
	-webkit-font-smoothing: antialiased;
	margin-bottom:20px;
}
.c-inner-content__list li:before  {
	content:'•';
	margin-right:5px;
	margin-left:-10px;
}

.list-spacer {
	margin-bottom:10px;
}

.c-inner-content__cta {
	padding:80px 0px;
	text-align:center;
}

.c-inner-content__cta h3 {
	text-transform:initial;
	font-size: 22px;
    max-width: 380px;
    margin: 0 auto 30px;
}

.c-inner-content__cta p {
	max-width:600px;
	margin:0 auto 30px;
}

.c-inner-content__cta strong {
	color:$black;
}

.c-inner-content .c-button img {
	margin-bottom:0px;
	margin-top:0px;
}

.c-inner-content__bg-circle {
	position:relative;

	@include mq('large') {
		display: none;
	}
}

.c-inner-content__bg-circle:before {
	content:'';
	position:absolute;
	right:0;
	bottom:-260px;
	left:0;
	margin:0 auto;
	width:650px;
	height:650px;
	background:#f7f7f7;
	border-radius:50%;
	z-index: -1;
}
