.c-header .row {
	height:105px;

	@include mq('small') {
		flex-flow:row wrap;
		height:150px;

		.c-header-logo {
			flex-basis:100%;
			justify-content: center;
		}
		
		.nav-wrapper,
		.c-header-contact {
			flex-basis:50%;
			flex-grow:1;
			justify-content: center;
		}
	}

	@include mq('xsmall') {
		.nav-wrapper,
		.c-header-contact a {
			justify-content: center;
		}
	}
}

.c-header-logo {
	text-align:center;
	padding:20px 40px;
	max-width:350px;
	width:350px;

	a {
		width:100%;
		height:100%;
		display: flex;
		align-items:center;
		justify-content: center;
	}

	@include mq('small') {
		max-width: 100%;
	}
}

.c-header-logo img {
	max-width:250px;
	width:100%;
}

.c-header-contact {
	background:#083d77;
	position:relative;
	padding:0px 40px 0px 20px;;

	@include mq('medium') {
		padding:0px 10px;;
	}
}

.c-header-contact:before {
	content:'';
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	margin:auto 0;
	display:block;
	width:1px;
	height:40px;
	background:$blue-dark;
}

.c-header-contact > ul  {
	width:100%;
	padding-left:0px;
	list-style-type:none;
}

.c-header-contact li {
	position:relative;
}

.c-header-contact li a {
	font-family:$montserrat;
	font-size:14px;
	line-height:20px;
	text-transform:uppercase;
	color:$white;
	-webkit-font-smoothing: antialiased;
}

.c-header-contact .fa {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid $blue-light;
    border-radius: 50%;
    margin:0px 10px 0px 0px;
}

.c-header-contact .fa {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid $blue-light;
    border-radius: 50%;
    margin:0px 10px 0px 0px;
    transition:all 300ms ease-in-out;
}
.c-header-contact .fa:hover {
	background:$blue-light;
}

.c-header-contact > ul > li:first-child {
	@include mq('large') {
		display: none;
	}
}

