.banner-wrapper {
	position:relative;
}

.c-home-slider {
	position:relative;
}

.scroll-down {
	position:absolute;
	bottom:30px;
	left:0;
	right:0;
	margin:0 auto;
	width:60px;
	cursor:pointer;

	@include mq('xsmall') {
		display: none;
	}
}

.c-home-slider__slide {
	height: 600px !important;
	position:relative;
	overflow:hidden;
}

.c-home-slider__slide h2 {
	margin: 0px;
	color: white;
	text-transform:uppercase;
	position:relative;
	z-index: 1;
	font-size:55px;
	line-height:55px;

	@include mq('xsmall') {
		font-size:30px;
		line-height: 35px;
		text-align: center;
		margin-top:-70px;
	}
}

.slick-vertical .slick-slide.c-home-slider__slide {
	border: none;
}

.c-home-slider .slick-dots {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	list-style-type:none;

	@include mq('xsmall') {
		right:0px;
		left:0px;
		margin:0 auto;
		padding:0px;
		top:initial;
		bottom:60px;
		display:flex;
		flex-flow:row wrap;
		justify-content: center;
		align-items:center;
	}
}

.c-home-slider .slick-dots li {
	display:block;
	margin:0 3px;
}

.c-home-slider .slick-dots li button {
	border-radius: 50%;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 2px solid $white;
    position: relative;
    transition: all 300ms ease-in-out;
    font-size:0px;
}

.c-home-slider .slick-dots li button:focus {
	outline:0;
}

.c-home-slider .slick-dots li button:before {
	opacity:0;
}


.c-home-slider .slick-dots li.slick-active button:before {
	opacity:1;
}

.c-home-slider .slick-dots li button:before,
.c-home-slider .slick-dots li.slick-active button:before {
	content:'';
	display:block;
	width:8px;
	height:8px;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
	background:$white;
	border-radius:50%;
	transition:opacity .3s;
}

.c-home-slider .slick-dots li:hover button:before {
	opacity:1;
}

.c-home_slider__slide-content {
	height: 100% !important;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display:flex;
	flex-flow: column wrap;
	justify-content:center;
	padding-left:40px;
	position:relative;

	&:before {
		display:none;
	}

	@include mq('xsmall') {
		padding:0px 20px;
	}
}

.c-home-slider__btn-wrapper {
	position:relative;
	z-index:1;
}

.c-home-slider__btn-wrapper {
	margin-top:20px;

	@include mq('xsmall') {
		text-align: center;
	}
}
