.c-inner-icons-section {
	list-style-type:none;
	padding:0px;
	margin:80px 0px;

	@include mq('large') {
		margin:30px 0px;
	}
}

.c-inner-icons-section  .icon-wrapper img {
	width: 100%;
	margin-bottom:10px;

	@include mq('medium') {
		width:200px;
	}
}

.c-inner-icons-section .icon-wrapper {
	width:40%;

	@include mq('medium') {
		width:auto;
	}
}

.c-inner-icons-section .content {
	width:100%;
	text-align:center;
}