.c-inner-nav {
	padding:30px 40px 30px 0px;

	@include mq('large') {
		padding:40px 20px;
		border-right:0px;
		text-align: center;
	}
}

.c-inner-nav.fixed {
	position: fixed;
	top: 33px;

	@include mq('large') {
		position: static;
	}
}

.c-inner-nav p {
	max-width: 220px;
	margin:40px 0px 10px;

	@include mq('large') {
		margin:20px auto 20px;
		max-width: 250px;
	}
}

.c-inner-nav ul {
	list-style-type: none;
	padding-left:0px;
}

.c-inner-nav ul li a {
	display: block;
	font-family: $montserrat;
	font-size: 14px;
	line-height:20px;
	color:$black;
	position:relative;
	text-transform:uppercase;
	margin-bottom:10px;
	transition:all 300ms ease-in-out;
	text-decoration:none;
}
.c-inner-nav ul li a:hover,
.c-inner-nav ul li a.active {
	padding-left:10px;
	color:$blue-light;

	@include mq('large') {
		padding-left:0px;
	}
}

.c-inner-nav ul li a:before {
	content:'';
	display:block;
	width:2px;
	height:10px;
	background:$blue-light;
	position:absolute;
	left:0px;
	top:0px;
	bottom:0px;
	margin:auto 0;
	transition:opacity .3s;
	opacity:0;

	@include mq('large') {
		display:none;
	}
}

.c-inner-nav ul li a.active:before,
.c-inner-nav ul li a:hover:before {
	opacity:1;
}
