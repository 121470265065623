.c-button {
	font-family:$roboto;
	font-size:12px;
	font-weight:bold;
	line-height:18px;
	letter-spacing:1px;
	color:$white;
	display: inline-flex;
    justify-content: space-between;
    align-items: center;
	padding: 15px 30px;
	background:$blue-light;
	text-transform:uppercase;
	margin-top:10px;
	border:2px solid $blue-light;
	-webkit-font-smoothing: antialiased;
	text-decoration:none;
	transition:all 300ms ease-in-out;
}

a.c-button:focus, a.c-button:visited,
a.c-button-border-style:focus, a.c-button-border-style:visited {
	text-decoration:none;
	color:#fff;
	outline:0;
}

.c-button:hover {
	color:$white;
	background:#0b7cf5;
	border-color:#0b7cf5;
}

.c-button img {
	width:15px;
	margin-left:10px;
	transition:all 300ms ease-in-out;
}

.c-button:hover img {
	margin-left:20px;
}

.c-button-border-style {
	font-family:$roboto;
	font-size:12px;
	font-weight:bold;
	line-height:18px;
	letter-spacing:1px;
	color:$white;
	display: inline-flex;
    justify-content: space-between;
    align-items: center;
	padding: 15px 30px;
	background:transparent;
	text-transform:uppercase;
	margin-top:10px;
	border-top:2px solid transparent;
	border-bottom:2px solid $blue-light;
	-webkit-font-smoothing: antialiased;
	margin-left:20px;

	@include mq('xsmall') {
		margin-left:0px;
	}
}

.c-button-border-style:hover {
	color:$white;
}

.c-button-border-style img {
	width:15px;
	margin-left:10px;
	transition:all 300ms ease-in-out;
}

.c-button-border-style:hover img {
	margin-left:20px;
}