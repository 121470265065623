.c-home-icons-section {
	list-style-type:none;
	padding:0px;
	margin-top:80px;

	@include mq('xsmall') {
		margin-top: 40px;
	}
}

.c-home-icons-section  .icon-wrapper img {
	width: 100%;

	@include mq('medium') {
		width:200px;
	}
	
}

.c-home-icons-section .icon-wrapper {
	width:40%;
	position:relative;

	@include mq('medium') {
		width:auto;
	}
}

.c-home-icons-section .icon-wrapper:before {
	content:'';
	display:block;
	width: 220px;
	height:220px;
	border-radius:50%;
	background:$white;
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	margin:auto 0;
	z-index: -1;
	opacity:0.7;

	@include mq('large') {
		display: none;
	}
}

.c-home-icons-section .content {
	width:60%;
	text-align:left;

	@include mq('medium') {
		width:80%;
		text-align: center;
		margin-bottom: 30px;
	}
}