// html,
// body,
// .banner-wrapper,
// .c-home-slider,
// .slick-list,
// .c-home-slider__slide {
// 	height:100% !important;
// }

#home-about {
	padding:60px 0px 100px;
	position:relative;

	@include mq('xsmall') {
		padding:40px 15px 40px;
	}
}

.home-services-arrow img {
	width:30px;
	margin-bottom:20px;
	transition:all 500ms ease;
}

.home-services-wrapper:hover .home-services-arrow img {
	margin-left:20px;
}

#home-services {
	overflow:hidden;
	padding-top:30px;
}

#home-services .row {
	margin-left:-30px;
	margin-right:-30px;
}

.home-services-wrapper {
	padding:0px 40px;
	height:600px;
	background-repeat:no-repeat;
	background-position:center 110px;

	@include mq('small') {
		padding:0px 0px;
	}
}

.home-services-single {
	text-align:center;
	max-width:400px;
	margin:0 auto;
	height:100%;
	padding:30px 40px 60px;
	transition:all 300ms ease-in-out;
}

.home-services-wrapper:hover .home-services-single {
	background: rgba(8,61,119,0.9);
	box-shadow: 0px 0px 40px rgba(25,25,25,0.35);
}

.home-services-wrapper .home-services-single h3 {
	position:relative;
	transition:color 300ms ease-in-out;
	max-width:100%;
}

.home-services-wrapper:hover .home-services-single h3 {
	color:$white;
}

.home-services-wrapper .home-services-single h3:before {
	content:'';
	display:block;
	width: 30px;
	height:3px;
	background:$blue-light;
	margin:0px auto 10px;
}

.home-services-single p {
	font-family:$roboto;
	font-size:14px;
	line-height:24px;
	color:$white;
}

.home-services-single p,
.home-services-single div {
	opacity:0;
	transition: opacity 300ms ease-in-out;
	display:inline-block;
	width:100%;
}

.home-services-wrapper:hover .home-services-single p,
.home-services-wrapper:hover .home-services-single div {
	opacity:1;
}

.home-image-circle {
	position:relative;
	padding:30px;
}

.home-image-circle:before {
	content:'';
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	margin:auto;
	width:450px;
	height:450px;
	background:$white;
	z-index:-1;
	border-radius:50%;

	@include mq('small') {
		display: none;
	}
}

.home-image-circle img {
	max-width:400px;
	flex-shrink:0;
}

#home-about-2 {
	padding:100px 15px;
	position:relative;

	@include mq('large') {
		text-align: center;
	}
}

#home-about-2 h2 {
	font-family:$montserrat;
	font-size:22px;
	line-height:28px;
	color:$black;
	margin-bottom:20px;
}

#home-about-2 .row {
	@include mq('large') {
		justify-content:center;
	}
}